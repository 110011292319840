import {
  AppBar,
  TitlePortal,
  UserMenu,
  ToggleThemeButton,
  LocalesMenuButton,
  RefreshIconButton,
} from "react-admin";
import logo from "assets/logo2.svg";
import { Box } from "@mui/material";

const AllToolbars = () => (
  <>
    <LocalesMenuButton />
    <ToggleThemeButton />
    <RefreshIconButton />
  </>
);

const MyUserMenu = (props: any) => <UserMenu {...props} />;
export const MyAppBar = () => (
  <AppBar userMenu={<MyUserMenu />} toolbar={<AllToolbars />}>
    <img src={logo} alt="logo" style={{ height: 20 }} />
    <Box component="span" flex={1} />{" "}
    <TitlePortal variant="body2" component="h2" />
  </AppBar>
);
export default MyAppBar;
