/*import { Login } from "react-admin";

const LoginPage = () => <Login />;

export default LoginPage;*/

import * as React from "react";
import { useTranslate } from "react-admin";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Logo from "assets/logo-black.png";
import Cover from "assets/cover.png";
import { useLogin, useNotify } from "react-admin";
import InputIcon from "@mui/icons-material/Input";
const LoginPage = () => {
  const translate = useTranslate();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const login = useLogin();
  const notify = useNotify();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log("data", email, password);
    login({ username: email, password }).catch(() =>
      notify("Invalid email or password")
    );
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(" + Cover + ")",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            alt=""
            src={Logo}
            style={{ height: "100px", marginBottom: "20px" }}
          />
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              value={email}
              variant="standard"
              onChange={(e) => setEmail(e.target.value)}
              color="success"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              color="success"
              label="Password"
              variant="standard"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              id="LoginButton"
              startIcon={<InputIcon />}
              sx={{ mt: 3, mb: 2 }}
            >
              {translate("pages.login.signin")}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
export default LoginPage;
