import inMemoryJWT from "providers/inMemoryJwt";
import { AuthProvider } from "react-admin";
import UserLogin from "interfaces/UserLogin";

const authProvider: AuthProvider = {
  login: (user: UserLogin) => {
    const request = new Request(
      process.env.REACT_APP_BASE_API + "/auth/local",
      {
        method: "POST",
        body: JSON.stringify({
          identifier: user.username,
          password: user.password,
        }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ jwt, user }) => {
        console.log("JWT", jwt, user);
        inMemoryJWT.setToken(jwt, user);
      });
  },
  logout: () => {
    inMemoryJWT.ereaseToken();
    return Promise.resolve();
  },

  checkAuth: () => {
    return inMemoryJWT.getToken() ? Promise.resolve() : Promise.reject();
  },

  checkError: (error: any) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      inMemoryJWT.ereaseToken();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getIdentity: () => {
    const user = inMemoryJWT.getUser().name;

    return Promise.resolve({
      id: 1,
      fullName: user,
      avatar: "https://api2.terah.app/uploads/thumbnail_me_45b8234d68.jpg",
    });
  },
  getPermissions: () => {
    return inMemoryJWT.getToken() ? Promise.resolve() : Promise.reject();
  },
};

export default authProvider;
