

const inMemoryJWTManager = () => {


    // This listener allows to disconnect another session of react-admin started in another tab
    window.addEventListener('storage', (event) => {
        if (event.key === 'ra-logout') {
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("user");
        }
    });

    const getToken = () => window.localStorage.getItem("token");

    const getUser = () => {
        const user = JSON.parse(window.localStorage.getItem("user"));
        console.log(user);
        return user;
    }
    const setToken = (token, user) => {


        window.localStorage.setItem("token", token);
        window.localStorage.setItem("user", JSON.stringify(user));
        return true;
    };

    const ereaseToken = () => {

        window.localStorage.removeItem("token");
        window.localStorage.removeItem("user");
        window.localStorage.setItem('ra-logout', Date.now());
        return true;
    }

    return {
        ereaseToken,
        getUser,
        getToken,
        setToken,
    }
};

export default inMemoryJWTManager();    