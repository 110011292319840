import { strapiRestProvider } from "ra-strapi-v4-rest";
import { fetchUtils } from "react-admin";
import inMemoryJWT from "providers/inMemoryJwt";

const strapiApiUrl: any = process.env.REACT_APP_BASE_API;

const httpClient = (url: string, options: any) => {
  options.headers =
    options.headers || new Headers({ Accept: "application/json" });
  options.headers.set("Authorization", `Token ${inMemoryJWT.getToken()}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = strapiRestProvider(strapiApiUrl, httpClient);

export default dataProvider;
