import englishMessages from "ra-language-english";

const en = {
  ...englishMessages,
  pages: {
    login: {
      signin: "Sign in ",
    },
  },
  resources: {
    "dial-codes": {
      name: "Countries",
      fields: {
        name: "Name",
        dialCode: "Dial Code",
        code: "Code",
        flagUrl: "Flag",
        createdAt: "Created at",
        updatedAd: "Updated at",
      },
    },
  },
};

export default en;
