import {
  Datagrid,
  EditButton,
  ImageField,
  List,
  TextField,
  TextInput,
} from "react-admin";

//TODO: Missing translations for the fields?
const postFilters = [
  <TextInput source="name_q" defaultValue="" key="1" label="Nome" />,
  <TextInput source="code_q" defaultValue="" key="2" label="Code" />,
  <TextInput source="dialCode_q" defaultValue="" key="3" label="Dial Code" />,
];
const CountryList = () => (
  <List filters={postFilters} sort={{ field: "id", order: "DESC" }}>
    <Datagrid>
      <TextField source="id" />
      <ImageField
        source="flagUrl"
        sx={{ "& img": { maxWidth: 20, maxHeight: 20, objectFit: "contain" } }}
      />
      <TextField source="name" />
      <TextField source="code" />
      <TextField source="dialCode" />
      <EditButton />
    </Datagrid>
  </List>
);
export default CountryList;
