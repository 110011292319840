import {
  Admin,
  Resource,
  ShowGuesser,
  EditGuesser,
  defaultTheme,
} from "react-admin";

//Import pages

//providers
import { i18nProvider } from "i18n/i18nprovider";

import MyLayout from "theme/layouts";

//pages
import LoginPage from "pages/login";
//icons
import FlagIcon from "@mui/icons-material/Flag";
import MedicationIcon from "@mui/icons-material/Medication";
import dataProvider from "providers/restProvider";
import authProvider from "providers/authProvider";

//Components
import CountryList from "components/countries";
import DrugList from "components/drugs";

export const theme = {
  ...defaultTheme,
  palette: {
    primary: {
      light: "#757ce8",
      main: "#5fb8a0",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#757ce8",
      main: "#5fb8a0",
      dark: "#002884",
      contrastText: "#fff",
    },
  },
};

const AdminPage = () => (
  <Admin
    i18nProvider={i18nProvider}
    loginPage={LoginPage}
    layout={MyLayout}
    theme={theme}
    dataProvider={dataProvider}
    authProvider={authProvider}
  >
    <Resource
      name="dial-codes"
      list={CountryList}
      icon={FlagIcon}
      options={{ label: "Posts" }}
      show={ShowGuesser}
      edit={EditGuesser}
    />
    <Resource name="drugs" list={DrugList} icon={MedicationIcon} />
  </Admin>
);

export default AdminPage;
