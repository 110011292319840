import polyglotI18nProvider from "ra-i18n-polyglot";
import { resolveBrowserLocale } from "react-admin";

import en from "i18n/en";
import pt from "i18n/pt";

const translations: any = {
  en,
  pt,
};
export const i18nProvider = polyglotI18nProvider(
  (locale: any) =>
    translations[locale] ? translations[locale] : translations.en,
  resolveBrowserLocale(),
  [
    { locale: "en", name: "Inglês" },
    { locale: "pt", name: "Português" },
  ]
);
