import portugueseMessages from "ra-language-portuguese";

//override portuguese Messages
portugueseMessages.ra.auth = {
  auth_check_error: "Por favor, faça login para continuar",
  user_menu: "Perfil",
  username: "Utilizador",
  password: "Password",
  sign_in: "Entrar",
  sign_in_error: "Erro na autenticação, tente novamente.",
  logout: "Sair",
};
export const pt = {
  ...portugueseMessages,
  pages: {
    login: {
      signin: "Entrar ",
    },
  },
  resources: {
    "dial-codes": {
      name: "Paises",
      fields: {
        name: "Nome",
        dialCode: "Indicativo",
        code: "Sigla",
        flagUrl: "Bandeira",
        createdAt: "Criado em",
        updatedAd: "Actualizado em",
      },
    },
  },
};
export default pt;
