import { Datagrid, List, TextField } from "react-admin";

//todo deal with translation
export const DrugList = () => (
  <List>
    <Datagrid>
      <TextField source="nRegisto" label="Numero de Registo" />
      <TextField source="nome" />
    </Datagrid>
  </List>
);

export default DrugList;
